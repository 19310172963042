<template>
  <div>
    <v-card>
      <v-card-title>Respondent Count</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete
              :items="projects"
              item-text="name"
              item-value="_id"
              autocomplete
              label="Choose Project"
              v-model="criteria.projectId"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="6">
            <v-btn
              :disabled="!criteria.projectId"
              color="primary"
              @click="getRespondentCount()"
            >Get Total Respondents</v-btn>
          </v-col>
        </v-row>

        <h2 class="text-xs-center">Total Respondents: {{ totalRespondents.respondentCount }}</h2>
      </v-card-text>
    </v-card>

    <v-card class="mt-12">
      <v-card-title>Delete Respondents</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete
              :items="projects"
              item-text="name"
              item-value="_id"
              autocomplete
              label="Choose Project"
              v-model="deleteCriteria.projectId"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="6">
            <v-btn
              :disabled="!deleteCriteria.projectId"
              color="primary"
              @click="deleteRespondents()"
            >Delete Respondents</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mt-12">
      <v-card-title>Sync Respondents</v-card-title>

      <v-card-text>
        <p>Note: One page is 100 respondents. If you have 550 respondents, you will need to fetch page 1 to page 6</p>
        
        <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete
              :items="projects"
              item-text="name"
              item-value="_id"
              autocomplete
              label="Choose Project"
              v-model="syncCriteria.projectId"
              v-validate="'required'"
              data-vv-name="project"
              :error-messages="errors.collect('project')"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              label="Page Number"
              v-validate="'required|numeric'"
              required
              type="number"
              data-vv-name="page number"
              :error-messages="errors.collect('page number')"
              v-model.number="syncCriteria.pageNumber"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-btn
          color="primary"
          :disabled="!syncCriteria.projectId"
          @click="syncRespondents()"
        >Sync Respondents</v-btn>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{snackText}}
      <v-btn @click="snack = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import RestResource from "@/services/dataServiceBuyer.js";
const service = new RestResource();
export default {
  data() {
    return {
      projects: [],
      criteria: {},
      deleteCriteria: {},
      totalRespondents: [],
      syncCriteria: {},
      snack: false,
      snackText: "",
      snackColor: ""
    };
  },

  mounted() {
    this.loadProjects();
  },

  methods: {
    loadProjects() {
      this.$setLoader();
      service.fetchProjects().then(r => {
        this.projects = r.data;
        this.$disableLoader();
      });
    },

    async deleteRespondents() {
      try {
          this.$setLoader();
          service.deleteRespondents(this.deleteCriteria).then(r => {
          r;
          this.$disableLoader();
          this.snack = true,
          this.snackText = "Successfully Delelted all Responses",
          this.snackColor = "red";
        })
      } catch (error) {
        this.snack = true,
        this.snackText = "Successfully Delelted all Responses" + error,
        this.snackColor = "red";
      }

    },

    async getRespondentCount() {
      this.$setLoader();
      service.getRespondentCount(this.criteria).then(r => {
        this.totalRespondents = r.data;
        this.$disableLoader();
        this.snack = true,
        this.snackText = "Success",
        this.snackColor = "green";
      });
    },

    async syncRespondents() {
      let g = await this.$validator.validate();
      if (g) {
        try{
        this.$setLoader();
        service.syncRespondents(this.syncCriteria).then(r => {
            r;
            this.$disableLoader();
            this.snack = true,
            this.snackText = "Successfully Synced all Respondents",
            this.snackColor = "blue"
          })
        } catch(error)
          {
            this.snack = true,
            this.snackText = "Error Syncing Respondents" + error,
            this.snackColor = "red";
          }
      }
    }
  }
};
</script>
